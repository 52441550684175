@import url('https://fonts.cdnfonts.com/css/merchant-copy-doublesize');
@import url('https://fonts.cdnfonts.com/css/ibm-plex-mono');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700&display=swap');

@font-face {
  font-family: 'Candola';
  src: url('/public/fonts/candola_bold.otf');
  font-weight: bold;
}

@font-face {
  font-family: 'Candola';
  src: url('/public/fonts//candola_regular.otf');
  font-weight: normal;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

html {
  min-height: 100vh;
  background: #E8EAF4;
  /* background-image:
    radial-gradient(at 28% 60%, hsla(226, 85%, 75%, 1) 0px, transparent 50%),
    radial-gradient(at 17% 88%, hsla(303, 65%, 69%, 1) 0px, transparent 50%),
    radial-gradient(at 70% 20%, hsla(246, 92%, 65%, 1) 0px, transparent 50%),
    radial-gradient(at 79% 90%, hsla(302, 88%, 72%, 1) 0px, transparent 50%),
    radial-gradient(at 40% 67%, hsla(211, 79%, 67%, 1) 0px, transparent 50%),
    radial-gradient(at 20% 31%, hsla(269, 60%, 68%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 0%, hsla(246, 92%, 65%, 1) 0px, transparent 50%); */

}

TextField {
  background-color: #f8f8f8;
}


@media all and (min-width: 0px) {

  /* CSS rules here for screens above 550px */
  .foo {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5vh;
    padding-bottom: 25vh;
    justify-content: top;
    color: rgb(0, 0, 0);
  }

  .foo>* {
    background-color: #fdfdfd;
    max-width: 80%;
    padding: 5%;
    box-shadow: 4px 4px 10px rgb(161, 165, 219);
  }

  /* Hacky lil ripped paper effect */
  /* .App-header::after {
    content: "";
    position: relative;
    top: 100%;
    height: 50px;
    width: 500px;
    left: 0;
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/23618/rip.svg) bottom;
    background-size: 200%;

  } */

}





.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Strikethrough stuff for quantities */
.strikethrough {
  position: relative;
  margin-right: 0.4em;
}

.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid;
  border-radius: 3px;
  border-color: red;
  margin: 0 -0.2em;

  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-45deg);
}

.strikethrough:after {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid;
  border-color: red;
  margin: 0 -0.2em;


  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(45deg);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}